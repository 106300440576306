import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import { MDXRenderer } from "gatsby-plugin-mdx";
import LocalizedLink from '../components/LocalizedLink';
import MdxLink from '../components/MdxLink';

const Team = props => {
  const { intro } = props.data;
  const introImageClasses = "intro-image " + (intro.frontmatter ? `${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}` : "");

  return (
    <>
      <SEO title={intro.frontmatter.title} />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <MDXRenderer components={{ a: MdxLink }}>{intro.body}</MDXRenderer>
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query TeamQuery ($locale: String!) {
    intro: mdx(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: {regex: "/content\/team....mdx$/"}
	) {
      body
      frontmatter {
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
  }
`;

export default Team;
